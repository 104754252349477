var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ValidatedInput", {
        attrs: {
          translationKey: "transaction.amount",
          field: _vm.$v.amount,
          value: _vm.$v.amount.$model
        },
        on: {
          "update:value": function($event) {
            return _vm.$set(_vm.$v.amount, "$model", $event)
          }
        }
      }),
      _c("ValidatedSelect", {
        attrs: {
          translationKey: "transaction.environmentType",
          field: _vm.$v.environmentType,
          options: _vm.environmentTypeOptions,
          value: _vm.$v.environmentType.$model
        },
        on: {
          "update:value": function($event) {
            return _vm.$set(_vm.$v.environmentType, "$model", $event)
          }
        }
      }),
      _c("ValidatedSelect", {
        attrs: {
          translationKey: "transaction.paymentProvider",
          field: _vm.$v.paymentProvider,
          options: _vm.paymentProviderOptions,
          value: _vm.$v.paymentProvider.$model
        },
        on: {
          "update:value": function($event) {
            return _vm.$set(_vm.$v.paymentProvider, "$model", $event)
          }
        }
      }),
      _vm.paymentProvider == "PayPal"
        ? _c(
            "CCard",
            [
              _c("CCardHeader", [_vm._v("PayPal")]),
              _c(
                "CCardBody",
                [
                  !_vm.loading && _vm.environmentType != ""
                    ? _c("PayPal", {
                        attrs: {
                          amount: _vm.amountNumber,
                          currency: "EUR",
                          client: {
                            sandbox: _vm.payPalSandboxClientId,
                            production: _vm.payPalLiveClientId
                          },
                          env: _vm.payPalenvironmentType,
                          "button-style": { layout: "vertical", color: "gold" }
                        },
                        on: {
                          "payment-authorized": _vm.onPaymentAuthorized,
                          "payment-completed": _vm.onPaymentCompleted,
                          "payment-cancelled": _vm.onPaymentCancelled
                        }
                      })
                    : _vm._e(),
                  _vm.environmentType == "Sandbox"
                    ? _c("div", [
                        _c("h2", [_vm._v("Environment Details PayPal")]),
                        _c("h3", [_vm._v("Paypal")]),
                        _c("dl", [
                          _c("dd", [_vm._v("Email")]),
                          _c("dt", [
                            _vm._v("sb-caxsb20697328@personal.example.com")
                          ]),
                          _c("dd", [_vm._v("Passwort")]),
                          _c("dt", [_vm._v("ZrVnC7/S")])
                        ])
                      ])
                    : _vm._e(),
                  _c("div", [
                    _vm._v(
                      " PayPal Return: " + _vm._s(_vm.payPalReturnData) + " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.paymentProvider == "BrainTree"
        ? _c(
            "CCard",
            [
              _c("CCardHeader", [_vm._v("Braintree")]),
              _c(
                "CCardBody",
                [
                  !_vm.loading && _vm.environmentType != ""
                    ? _c("v-braintree", {
                        attrs: {
                          locale: "de",
                          paypal: _vm.paypalOptions,
                          authorization: _vm.clientToken
                        },
                        on: { success: _vm.onSuccess, error: _vm.onError }
                      })
                    : _vm._e(),
                  _vm.environmentType == "Sandbox"
                    ? _c("div", [
                        _c("h2", [_vm._v("Environment Details Braintree")]),
                        _c("h3", [_vm._v("Kreditkarte")]),
                        _c("dl", [
                          _c("dd", [_vm._v("Nummer")]),
                          _c("dt", [_vm._v("4111111111111111")]),
                          _c("dd", [_vm._v("Ablaufdatum")]),
                          _c("dt", [_vm._v("Egal")]),
                          _c("dd", [_vm._v("Mehr Nummern")]),
                          _c("dt", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "https://developer.paypal.com/braintree/docs/guides/credit-cards/testing-go-live/dotnet"
                                }
                              },
                              [_vm._v("Doku")]
                            )
                          ])
                        ]),
                        _c("h3", [_vm._v("Paypal")]),
                        _c("dl", [
                          _c("dd", [_vm._v("Email")]),
                          _c("dt", [
                            _vm._v("sb-botxr19608317@personal.example.com")
                          ]),
                          _c("dd", [_vm._v("Passwort")]),
                          _c("dt", [_vm._v(">G%S6i#D")])
                        ])
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "CCard",
        [
          _c("CCardHeader", [_vm._v("Stripe")]),
          _c(
            "CCardBody",
            [
              _c("StripeCheckout", {
                ref: "checkoutRef",
                attrs: {
                  mode: "payment",
                  "line-items": _vm.lineItems,
                  "success-url": _vm.successURL,
                  "cancel-url": _vm.cancelURL
                },
                on: {
                  loading: function(v) {
                    return (_vm.loading = v)
                  }
                }
              }),
              _vm.environmentType == "Sandbox"
                ? _c("div", [_c("h2", [_vm._v("Environment Details Stripe")])])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }